<template>
  <div v-show="empty_flag" class="empty_page">
    <div class="font_sy">
      loading!!!
    </div>
  </div>

</template>

<script>

import {getToken} from "../../utils/token";
import {login} from "../../api/login";

export default {
  name: "loading_page",
  data(){
    return {
      empty_flag: true
    }
  },

  created() {
    if(!getToken()){
      login()
    }
    var num = 0
    let token = "-1"
    var time_id = setInterval(() => {
      num = num + 1
      if (token !== "-1" && token !== null) {
        if (token === null){
          console.log(typeof token)
          console.log(token)
        }

        this.empty_flag = false
        this.show_flag = true
        clearInterval(time_id)
        this.$router.push({
          path: "/home", query: {
          }
        });
      }else{
        token = getToken()
      }
      if (num === 20) {
        this.empty_flag = false
        clearInterval(time_id)
        this.$router.push({
          path: "/some_error", query: {
          }
        });
      }
    }, 1000)
  },

  mounted() {
  }
};
</script>

<style lang="less" scoped>

.empty_page{
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
  right: 0px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.font_sy {
  font-size: x-large;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
